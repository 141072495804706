if (process.env.REACT_APP_PORTAL === 'Admin') {
  import('./modules/admin/AppAdmin');
} else if (process.env.REACT_APP_PORTAL === 'Doctor') {
  import('./modules/doctor/AppDoctor');
} else if (process.env.REACT_APP_PORTAL === 'Patient') {
  import('./modules/patient/AppPatient');
} else if (process.env.REACT_APP_PORTAL === 'Partner') {
  import('./modules/partner/AppPartner');
} else {
  throw Error('Environment Variable REACT_APP_PORTAL has invalid value');
}

export {};
